body {
  background-color: rgba(129, 241, 142, 1);
}

* {
  box-sizing: border-box;
  outline: none;
  user-select: none;
}

#carousel-wrapper {
  margin-top: 50px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#menu {
  height: 380px;
  width: 720px;
  overflow: hidden;
  font-weight: 700;
  line-height: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  transition: all 0.6s ease-in-out;
}

#current-option {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translate(-25%, 0%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#current-option-text1 {
  font-size: 1.6rem;
  line-height: 3rem;
  width: 220px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#current-option-text1::before {
  content: attr(data-next-text);
  position: absolute;
  transform: translate(0%, 380px);
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#current-option-text1::after {
  content: attr(data-previous-text);
  position: absolute;
  transform: translate(0%, -380px);
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#current-option-text2 {
  font-size: 0.8rem;
  width: 220px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

#current-option-text2::before {
  content: attr(data-next-text);
  position: absolute;
  transform: translate(0%, 380px);
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

#current-option-text2::after {
  content: attr(data-previous-text);
  position: absolute;
  transform: translate(0%, -380px);
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

#previous-option {
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  display: block;
  cursor: pointer;
  background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 256 256'%3E%3Cpolygon points='225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093' fill='%23333'%3E%3C/polygon%3E%3C/svg%3E");
  background-size: cover;
  position: absolute;
  transform: translate(310px, 50px);
  margin-left: -25%;
}

#next-option {
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  display: block;
  cursor: pointer;
  background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 256 256'%3E%3Cpolygon points='225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093' fill='%23333'%3E%3C/polygon%3E%3C/svg%3E");
  background-size: cover;
  position: absolute;
  transform: translate(310px, -50px) rotate(180deg);
  margin-left: -25%;
}

#image {
  height: 240px;
  width: 240px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 101;
  position: absolute;
  transform: translate(140px, 0);
}

#carousel-wrapper.anim-next {
  pointer-events: none;
}

#carousel-wrapper.anim-next #current-option-text1,
#carousel-wrapper.anim-next #current-option-text2 {
  animation: next-text 0.65s 0.085s;
}

#carousel-wrapper.anim-next #previous-option {
  animation: next-top-arrow 0.65s 0.085s;
}

#carousel-wrapper.anim-next #next-option {
  animation: next-bottom-arrow 0.65s 0.085s;
}

#carousel-wrapper.anim-next #image {
  animation: next-image 0.65s 0.085s;
}

#carousel-wrapper.anim-previous {
  pointer-events: none;
}

#carousel-wrapper.anim-previous #current-option-text1,
#carousel-wrapper.anim-previous #current-option-text2 {
  animation: previous-text 0.65s 0.085s;
}

#carousel-wrapper.anim-previous #previous-option {
  animation: previous-top-arrow 0.65s 0.085s;
}

#carousel-wrapper.anim-previous #next-option {
  animation: previous-bottom-arrow 0.65s 0.085s;
}

#carousel-wrapper.anim-previous #image {
  animation: previous-image 0.65s 0.085s;
}

.aboutp{
  padding:20px
}
@keyframes previous-text {
  50%, 55% {
      transform: translate(0%, 390px);
  }
  to {
      transform: translate(0%, 380px);
  }
}

@keyframes previous-top-arrow {
  50% {
      transform: translate(310px, 53px);
  }
}

@keyframes previous-bottom-arrow {
  50% {
      transform: translate(310px, -47px) rotate(180deg);
  }
}

@keyframes previous-image {
  0% {
      transform: translate(140px, 0) scale(1);
      opacity: 1;
  }
  70% {
      transform: translate(140px, 0) scale(1.1);
      opacity: 0;
  }
  100% {
      transform: translate(140px, 0) scale(1);
      opacity: 1;
  }
}

@keyframes next-text {
  50%, 55% {
      transform: translate(0%, -390px);
  }
  to {
      transform: translate(0%, -380px);
  }
}

@keyframes next-top-arrow {
  50% {
      transform: translate(310px, 47px);
  }
}

@keyframes next-bottom-arrow {
  50% {
      transform: translate(310px, -53px) rotate(180deg);
  }
}

@keyframes next-image {
  0% {
      transform: translate(140px, 0) scale(1);
      opacity: 1;
  }
  70% {
      transform: translate(140px, 0) scale(1.1);
      opacity: 0;
  }
  100% {
      transform: translate(140px, 0) scale(1);
      opacity: 1;
  }
}

.aboutpart {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 50px;
  row-gap: 80px;
  background: rgba(129, 241, 142, 1);
  margin-bottom: 50px;
}

  .aboutright {
    background-color: white;
    display: grid;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
}


.lamp {
  height: 600px;
  width: 400px;
}

.aboutp {
  max-width: 700px;
  display: grid;
  align-items: center;

}

/* Responsive Styles */
@media (max-width: 1024px) {
  #carousel-wrapper {
      flex-direction: column;
  }

  #menu {
      width: 100%;
  }

  #previous-option,
  #next-option {
      position: static;
      transform: none;
      margin: 10px 0;
  }

  #image {
      transform: none;
      margin: 20px 0;
  }

  .aboutpart {
      grid-template-columns: 1fr;
  }

  .lamp {
      height: 400px;
      width: 100%;
  }

  .aboutp {
      max-width: 90%;
  }
}

@media (max-width: 768px) {
  #carousel-wrapper {
      margin-top: 20px;
  }

  #menu {
      height: auto;
  }

 .current-option-div1 #current-option-text1{
    font-size: 1rem;
    margin-left: -16%;

 }
 .current-option-div2 #current-option-text2 {
  font-size: 1rem;
  margin-left: 18%;

}

  #previous-option,
  #next-option {
      width: 1rem;
      height: 1rem;
  }
  .prev-div #previous-option{
    position: static;
    transform: rotate(180deg);
    margin-top:-320%;
  }
  .next-div #next-option{
    position: static;
    transform: none;
    margin-top:320%;
    margin-left: -90%;
  }

  #image {
      height: 200px;
      width: 200px;
  }

  .aboutpart {
      gap: 30px;
      row-gap: 50px;
  }

  .lamp {
      height: 300px;
  }
}
