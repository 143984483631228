.firstpart{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    justify-content: center;
    text-align: center;
    gap: 50px;
    row-gap: 80px;
    width: 100%;
    height: 100%;
    --color: rgba(0, 115, 19, 0.3);
    background-color: #191a1a;
    background-image: linear-gradient(
        180deg,
        transparent 24%,
        var(--color) 25%,
        var(--color) 26%,
        transparent 27%,
        transparent 74%,
        var(--color) 75%,
        var(--color) 76%,
        transparent 77%,
        transparent
      ),
      linear-gradient(
        45deg,
        transparent 24%,
        var(--color) 35%,
        var(--color) 26%,
        transparent 17%,
        transparent 74%,
        var(--color) 75%,
        var(--color) 76%,
        transparent 77%,
        transparent
      );
    background-size: 55px 55px;
    
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%; /* Adjust width as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  color: black; /* Change color if needed */
  font-size: 1.5rem; /* Adjust size to make the icons smaller */
  text-align: center;
  padding: 0;
}



.carousel-control-prev i,
.carousel-control-next i {
  font-size: 1.5rem; /* Adjust size for Font Awesome icons */
}

.firstleft{
  margin-top:150px;
}
.firstright{
    background-image: url('../Images/Rectangle%2036.svg');

     background-repeat: no-repeat;
    background-size: cover;

}


.galeritext1{
  padding: 20px;
  white-space: wrap;


}

.presentationpart{
  margin-top: 30px;
  
}

.carouselslide{
  margin-top: 50px;
}

.galeri{
  margin-top: 100px;
}

.firstimg{
    height: 420px;
    width: 700px;
    max-width: 100%;
    margin-top: 20%;
    border-radius: 10px;
    border:5px solid #81f18e;
    object-fit:cover;
    margin-left: -10%;
}

.inline-container{
  margin-bottom: 10%;
}
.firsttext{
  color: rgb(72, 230, 90);
  font-size: 60px;
}

button {
  font-family: monospace;
  background-color:  rgba(129, 241, 142, 1);
  ;
  color: white;
  border: none;
  border-radius: 8px;
  width: 140px;
  height: 55px;
  transition: .3s;
}

button:hover {
  background-color: white;
  color:  rgba(129, 241, 142, 1);
  border: 2px solid rgba(129, 241, 142, 1);
}

.presentation{
  margin-top: 70px;
  color: rgb(72, 230, 90);
}
.secondpic1{
  height: 400px;
  width: 550px;
 
}

.carousel-item {
  height: 750px; /* Adjust this value to make the carousel smaller */
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures images cover the container without changing aspect ratio */
}
.imageslider3{
  margin-top: 29%;
}

.secondpic2{
  position: relative;
  top: -250px;
  left:150px;
  height: 400px;
  width: 400px;
}

.secondtext {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 40px;

  justify-content: center;
  white-space: normal; /* Ensures text wraps normally */
  text-align: left; /* Aligns text to the left */
  margin: 0; /* Removes any default margins */
  width: 100%; /* Ensures the text uses the full width of the container */
  color: white;
  text-align: center;
}
.righttext{
  max-width: 580px;
  margin: 0 auto; 
  text-align: left; 
}

.right1{
  margin-top: -200px;
}

/* Loader */

.galeriright{
  background-color: rgba(129, 241, 142, 1);
  
}

.loader {
  
  --height-of-loader: 4px;
  --loader-color: rgba(129, 241, 142, 1);
  width: 130px;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgba(0,0,0,0.2);
  position: relative;
}

.loader::before {
  content: "";
  position: absolute;
  background: var(--loader-color);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 1s ease-in-out infinite;
  ;
}

@keyframes moving {
  50% {
    width: 100%;
  }

  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}

.secondpart{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 50px;
    row-gap: 80px;
}


h5,p{
    color: black;
}

/* Contact */
.card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 500px;
  
  display: flex;
  flex-direction: column;
  margin-left: 180px;
  border: 2px solid rgba(129, 241, 142, 1);

}

.title {
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  color: rgba(129, 241, 142, 1);
  
}

.form {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  
}

.group {
  position: relative;
}

.form .group label {
  font-size: 14px;
  color: rgb(99, 102, 102);
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  transition: all .3s ease;
  
}

.form .group input,
.form .group textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  outline: 0;
  width: 100%;
  background-color: transparent;
}

.form .group input:placeholder-shown+ label, .form .group textarea:placeholder-shown +label {
  top: 10px;
  background-color: transparent;
}

.form .group input:focus,
.form .group textarea:focus {
  border-color: #3366cc;
}

.form .group input:focus+ label, .form .group textarea:focus +label {
  top: -10px;
  left: 10px;
  background-color: #fff;
  color: #3366cc;
  font-weight: 600;
  font-size: 14px;
}

.form .group textarea {
  resize: none;
  height: 100px;
}

.form .button {
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.form .button:hover {
  background-color: white;
  color:  rgba(129, 241, 142, 1);
  border: 2px solid rgba(129, 241, 142, 1);
}

#comment{
  height: 200px;
}
  
.iconstyle {
  color:  rgba(129, 241, 142, 1);;
  font-size: 2rem;
  margin: 0 10px;
}
.inline-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.svg{
  max-width: 100%;
  width:100%;
  margin-top: -15%;
}
  .location{
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 50px;
    row-gap: 80px;
    width: 100%;
    height: 100%;
    --color: rgba(0, 115, 19, 0.3);
    background-color: #191a1a;
    background-image: linear-gradient(
        180deg,
        transparent 24%,
        var(--color) 25%,
        var(--color) 26%,
        transparent 27%,
        transparent 74%,
        var(--color) 75%,
        var(--color) 76%,
        transparent 77%,
        transparent
      ),
      linear-gradient(
        45deg,
        transparent 24%,
        var(--color) 35%,
        var(--color) 26%,
        transparent 17%,
        transparent 74%,
        var(--color) 75%,
        var(--color) 76%,
        transparent 77%,
        transparent
      );
    background-size: 55px 55px;
  }
  

  .locationimg{
    max-width: 100%;
    border-radius: 10px;
    border:5px solid #81f18e
  }
  .emailtext{
    color: rgb(72, 230, 90);
    font-size: 40px;
  }
  .email-icon{
    color:#81f18e;
    font-size: 30px;
    margin-bottom: 5%;
    transition: 1s;
  }
  .email-icon:hover{
    color:white;
    transform: scale(1.200);
  }
.nav-item a{
    color:black;
}
.nav-item a:hover{
  color:#81f18e;
}

 .card{margin-top: 20px;}

  .galeri{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 50px;
    row-gap: 80px;
  }

  .galeryimg{
    height: 700px;
    width: 700px;
  }

  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-top: -450px;
}




/* responsive */


/* Responsive Styles */
@media (max-width: 1024px) {
  .firstpart,
  .location,
  .galeri {
      grid-template-columns: 1fr;
      row-gap: 50px;
  }

  .firstimg,
  .secondpic1,
  .secondpic2,
  .locationimg,
  .galeryimg {
      height: 500px;
      width: 90%;
      margin-left: 1%;
      
  }


  svg{
    display: none;
  }
 
  .secondpic2 {
    position: relative;
    top: -250px;
    left: 30px;
   
}
  .firsttext {
      font-size: 40px;
  }

  .button,
  .form button {
      width: 120px;
      height: 45px;
  }

  .presentation {
      margin-top: 50px;
      font-size: 24px;
  }

  .secondtext,
  .righttext {
      margin-left: 20px;
      max-width: 90%;
  }

  .card {
      width: 100%;
      margin-left: 0;
      padding: 15px;
  }

  .title {
      font-size: 32px;
  }

  .form {
      margin-top: 30px;
  }
}

@media (max-width: 768px) {
  body{
    overflow-x: hidden;
    height: 100vh;
    width: 100%;

 }
 .galeritext1{
  padding:10px;
  margin-top: 20px;
  margin-bottom: 20px;
 }
 .image-box{
  margin-top: -30%;
 }
 .presentationpart{
  margin-bottom: 45%;
 }
#emotext{
  margin-bottom: -130px;
}
  .firstleft {
    margin-top: 50px;
}
.firstimg{
  height: 420px;
  width: 600px;
  max-width: 100%;
  margin-bottom: 5%;
  border-radius: 10px;
  border:5px solid #81f18e;
  
}
/* .firstright{
  display: flex;
  justify-content: center;
  align-items: center;
} */

.inline-container{
margin-bottom: -15%;

}
 
  svg {
    max-width: 100%;
    height: auto;
    display: none;
  }
  .firstpart,
  .location,
  .galeri {
      gap: 30px;
      row-gap: 30px;
  }

    .secondpart{
      display: grid;
      grid-template-columns: repeat(1,1fr);
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 50px;
      row-gap: 80px;
  }
  

.secondpic1,
.secondpic2 {
    height: auto;
    width: 40px;
    max-width: 300px;
}

/* .secondtext {
    font-size: 16px;
    margin: 0 10px;
} */
  .firstimg,
  .locationimg,
  .galeryimg {
      height: 200px;
      width: 90%;
      margin-left: 1%;
      object-fit:fill;
  }

  .left1{
    margin-bottom: 100px;
  }

  .svg{
    margin-top: 200px;
    height: 200px;
    width: auto;
  }


 
  .carousel-inner {
    position: relative;
    width: 100%;
    
    overflow: hidden;
    margin-top: -61px;
}
  .secondpic2{
  display: none;
  }
  .secondpic1{
    order: 1;
    height: 300px;
    width: 90%;
  }

  .firsttext {
      font-size: 30px;
  }


  .button,
  .form button {
      width: 100px;
      height: 35px;
  }

  .presentation {
      margin-top: 20px;
      font-size: 25px;
  }

  .secondtext,
  .righttext {
      margin: 0 auto;
      max-width: 90%;
      font-size: 18px;
      text-align: center;
      margin-bottom: 30px;
  }


  .card {
    margin-top: 20px;    
    width: 90%;          
    margin-left: auto;   
    margin-right: auto;  
    padding: 10px;      
}
  .title {
      font-size: 24px;
  }

  .form {
      margin-top: 20px;
  }

  .svg {
      top: -200px;
  }

  .imageslider3{
    margin-top: -150px;
  }
  .carousel-item {
    height: 550px; /* Adjust this value to make the carousel smaller */
  }
  
  .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures images cover the container without changing aspect ratio */
  }
}
