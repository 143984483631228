* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  h3 {
    font-size: 1.5em;
    font-weight: 700;
  }
  p {
    font-size: 1em;
    line-height: 1.7;
    font-weight: 300;
    color: var(--text-gray);
  }
  .description {
    white-space: wrap;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    gap: 24px;
    padding: 24px;
    margin-top: 5%;
    flex-wrap: wrap;
    border:1px solid black;
    border-radius: 20px;

  }
  .wrap1 {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    gap: 24px;
    padding: 24px;
    flex-wrap: wrap;
    border:1px solid black;
    margin-top: 2%;
    border-radius: 20px;
  }


  .text{
    text-align: center;
    margin:5%;
    margin-bottom: 10%;
    color: rgb(72, 230, 90);
    
  }
  .text h1{
    font-weight:bolder;
  }
  
  .box {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;
    padding: 24px;
    background: #fff;
   
  }
  
  .box-top {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 12px;
    margin-bottom: 36px;
   
  }
  
  .box-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: 50% 20%;
    transition: .1s;
  }

  .box-image:hover{
    transform: scale(1.100);
  }
  .box-title14{
    text-align: center;
  }
  .title-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  
  .box-title {
    border-left: 3px solid var(--purple);
    padding-left: 12px;
  }
  
  .user-follow-info {
    color: hsl(0, 0%, 60%);
  }
  
  .button {
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: auto;
    padding: 16px;
    color: #000;
    background: transparent;
    box-shadow: 0px 0px 0px 1px black inset;
    transition: background 0.4s ease;
  }
  
  .button:hover {
    background: var(--purple);
  }
  
  .fill-one {
    background: var(--light-bg);
  }
  
  .fill-two {
    background: var(--pink);
  }

  
  .navigation{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .navigation button{
      border: none;
      margin-left: 100px;
      background-color: #E9E9E9;
      transition: .1s;
  }
  .navigation button:hover{
    transform: scale(1.100);
  } 
  .navigation2{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    border: 1px solid black;
    background-color:#E1DFDF ;
    
  }
  .navigation2 h1 {
    font-size:20px;
    font-weight: 900;
    color:black;
    padding:10px;
  }
  .text-box  #textii{
    font-size:30px;
    word-break: break-all;
    padding:15px
  }
  .text-box{
    width:700px;
    max-width: 100%;
    margin:100px;
  }
  .text-box h1{
    color:white;
    font-weight:600;
  }
  .image-box .image{
    max-width: 100%;
    width:400px;
  }
  .box1{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #81f18e;
    margin-top: 5%;
    box-shadow: 5px 5px 20px #81f18e;
    border-radius: 10px;
    transition: .3s;
    
  }
  .box1:hover{
    transform: scale(1.0500);
  }
 
 
  /* RESPONSIVE QUERIES */
  
  @media (min-width: 320px) {
    .title-flex {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
    }
    .user-follow-info {
      margin-top: 6px;
    }
    .navigation {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left:-100px;
    }
    .socialMedias{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .text-box  #textii{
      font-size: 25px;
      font-weight: 800;
    }
    .box1{
      display: flex;
      flex-direction: column;
      width:400px;
    }
    .text-box h1{
      font-size: 20px;
    }
    .image-box .image{
      width:300px
    }
  }
  
  @media (min-width: 460px) {
    .title-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
    }
    .user-follow-info {
      margin-top: 6px;
    }
    .navigation {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left:-100px;
    }
  }
  
  @media (min-width: 640px) {
    .box {
      flex-basis: calc(50% - 12px);
    }
    .title-flex {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
    }
    .user-follow-info {
      margin-top: 6px;
    }
      .navigation {
        display: flex;
        justify-content: center;
        align-items: center;
      flex-direction: column;
      margin-left:-100px;
    }
    .box1{
      display: flex;
      flex-direction: row;
      width:400px;
    }
    .text-box h1{
      font-size: 15px;
    }
    .image-box .image{
      width:400px
    }
  }
  
  @media (min-width: 840px) {
    .title-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
    }
    .user-follow-info {
      margin-top: 6px;
    }
    .navigation {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }
    .box1{
      display: flex;
      flex-direction: row;
      width:400px;
    }
    .text-box h1{
      font-size: 20px;
    }
    .image-box .image{
      width:400px
    }
  }
  
  @media (min-width: 1024px) {
    .box {
      flex-basis: calc(33.3% - 16px);
    }
    .title-flex {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
    }
    .user-follow-info {
      margin-top: 6px;
    }
    .navigation {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }
    .box1{
      display: flex;
      flex-direction: row;
      width:400px;
    }
    .text-box h1{
      font-size: 20px;
    }
    .image-box .image{
      width:400px
    }
  }
  
  @media (min-width: 1100px) {
    .box {
      flex-basis: calc(25% - 18px);
    }
    .navigation {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }
    .box1{
      display: flex;
      flex-direction: row;
      width:400px;
    }
    .text-box h1{
      font-size: 20px;
    }
    .image-box .image{
      width:400px
    }
  }
  