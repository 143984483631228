.textii123{
    color:black;
}
.logo-footer img{
    max-width: 100%;
    width:100px;
}
.texto23{
    color:black;
}
.texto23:hover{
    color:black;
}
#loc{
    margin-top:-2000px
}