.category-filter {
    margin-bottom: 50px;
    display: flex;
    justify-content: start;
    margin-left: 2.5%;
  }
  
  .category-filter button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    background-color: #81F18E;
    color: white;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: .1s;
  }
  .category-filter button:hover {
      transform: scale(1.100);
  }
  
  @media (max-width: 768px) {
    .category-filter{
      display: flex;
      flex-wrap: wrap;
      padding:5px 10px;
      flex-direction: column;
      margin-left:25%;
      
    }
    .category-filter button{
      margin-top: 10px;
      
    }
  }
  @media screen and (min-width: 1920px) {
    .category-filter{
      margin-left: 5.6%;
    }
  }